import dayjs from 'dayjs'
import { cls, formatAspectRatio, getSizeByResolutionAndAspectRatio, whisper } from '@/utils'
import { GenerationConfig, GenerationSetting, ModelVersion, OutputSpec, Size, TemplateGenerationParams } from '@/types'
import { Badge } from '@/components/ui/badge'
import ButtonCopy from '../copy-button'
import useAmplitude from '@/hooks/useAmplitude'
import { useMemo } from 'react'

interface CreationInfoProps {
  className?: string
  promptClassName?: string
  metaClassName?: string
  createTimeClassName?: string
  input_type?: string
  prompt?: string
  spec?: OutputSpec
  settings?: GenerationSetting
  creationId: string
  createTime: string
  type: string
  config: GenerationConfig | TemplateGenerationParams['config']
  model_version?: ModelVersion | null
  output_type?: string
}

// export to test it
export const getPromptPrefix = ({
  type,
  config,
  input_type,
  prompt,
}: Pick<CreationInfoProps, 'type' | 'config' | 'input_type' | 'prompt'>): string => {
  if (input_type === 'text' && !prompt) {
    return ''
  }
  const delimiter = ' + '

  let content = ''
  if (type === 'template' && (config as TemplateGenerationParams['config'])?.template_id) {
    // calculate file counts by config.template_inputs
    const nonEmptyKeys = Object.keys((config as TemplateGenerationParams['config'])?.template_inputs ?? {}).filter(
      (key) => (config as TemplateGenerationParams['config']).template_inputs?.[key],
    )
    const videoCount = nonEmptyKeys.filter((key) => key.includes('video') && key.includes('url')).length
    const imageCount = nonEmptyKeys.filter((key) => key.includes('image') && key.includes('url')).length

    content = [
      videoCount && `${videoCount} video${videoCount > 1 ? 's' : ''}`,
      imageCount && `${imageCount} image${imageCount > 1 ? 's' : ''}`,
    ]
      .filter(Boolean)
      .join(delimiter)
  } else {
    const fileCount = Math.max(
      1,
      Number(
        input_type === 'image'
          ? (config as GenerationConfig)?.source_images?.filter(Boolean)?.length ?? 1
          : input_type === 'video'
            ? 1
            : 0,
      ) || 0,
    )

    if (input_type === 'image') {
      content = `${fileCount} image${fileCount > 1 ? 's' : ''}`
    } else if (input_type === 'video') {
      content = '1 video'
    }
  }
  const showDelimiter = !!content && !!prompt
  return content + (showDelimiter ? delimiter : '')
}

export default function CreationInfo({
  className,
  promptClassName,
  metaClassName,
  createTimeClassName,
  input_type,
  prompt,
  settings,
  spec,
  creationId,
  config,
  createTime,
  type,
  model_version,
  output_type,
}: CreationInfoProps) {
  const { track } = useAmplitude()

  const tagClassName = 'rounded-sm text-text-subdued px-2 py-1 text-body-sm bg-surface-base hover:bg-surface-base/90'

  const prefix = useMemo(() => {
    return getPromptPrefix({
      type,
      config,
      input_type,
      prompt,
    })
  }, [type, config, input_type, prompt])

  const outputSize: Size | null = useMemo(() => {
    if (spec?.width && spec?.height) {
      return { width: spec.width, height: spec.height }
    }
    if (settings?.resolution && settings?.aspect_ratio) {
      return getSizeByResolutionAndAspectRatio({
        resolution: settings.resolution,
        aspectRatio: settings.aspect_ratio,
      })
    } else if (settings?.width && settings?.height) {
      return { width: settings.width, height: settings.height }
    }
    return null
  }, [settings, spec])

  const cameraMovement = (config as GenerationConfig)?.camera_movement

  return (
    <div className={cls('flex flex-col min-h-0', className)}>
      <div className={cls('flex-1 min-h-0 gap-2.5 flex', promptClassName)}>
        <div className='w-5'>
          <ButtonCopy
            className='p-0 size-5 text-icon-subdued'
            text={prompt}
            onCopied={(seed) => {
              track('click:creation:copy-prompt', { creation_id: creationId })
            }}
          />
        </div>
        <div className='h-max flex-1 no-scrollbar text-text'>
          {!!prefix && <span className='text-text-interactive shrink-0'>{prefix}</span>}
          {/* {!!prefix && !!prompt && <span className='text-text shrink-0'>&nbsp;+&nbsp;</span>} */}
          {prompt ? <span className='break-all'>{prompt}</span> : null}
        </div>
      </div>
      <div className={cls('flex flex-row flex-wrap gap-2 shrink-0 mt-auto', metaClassName)}>
        {creationId ? (
          <Badge className={tagClassName}>
            id
            <span className='ml-2 break-all md:break-normal'>{creationId}</span>
          </Badge>
        ) : null}
        {model_version ? (
          <Badge className={tagClassName}>
            model version
            <span className='ml-2'>haiper {model_version}</span>
          </Badge>
        ) : null}
        {settings?.seed ? (
          <Badge className={tagClassName}>
            seed
            <span className='ml-2'>{settings?.seed}</span>
          </Badge>
        ) : null}
        {settings?.aspect_ratio ? (
          <Badge className={tagClassName}>
            ar
            <span className='ml-2'>{formatAspectRatio(settings?.aspect_ratio ?? '--')}</span>
          </Badge>
        ) : null}
        {settings?.motion_level ? (
          <Badge className={tagClassName}>
            motion level
            <span className='ml-2'>{settings?.motion_level === -1 ? 'default' : settings?.motion_level}</span>
          </Badge>
        ) : null}
        {outputSize ? (
          <Badge className={tagClassName}>
            resolution
            <span className='ml-2'>
              {outputSize.width}x{outputSize.height}
            </span>
          </Badge>
        ) : null}
        {cameraMovement ? (
          <Badge className={tagClassName}>
            camera control
            <span className='ml-2'>{cameraMovement.replace(/_/g, ' ')}</span>
          </Badge>
        ) : null}
      </div>
      {createTime ? (
        <div className={cls('text-body-sm text-text-subdued', createTimeClassName)}>
          {dayjs(createTime).format('YYYY-MM-DD HH:mm')}
        </div>
      ) : null}
    </div>
  )
}
